//
//
//
//
//
//

export default {
  name: 'Monitor'
}
